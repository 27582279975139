export type TRootCategory = 2 | 105 | 188 | 366;
export type TRootCategoryNames = 'zhenskoe' | 'muzhskoe' | 'detskoe' | 'lifestyle';

export const categoryRootWomen = 'zhenskoe';
export const categoryRootMen = 'muzhskoe';
export const categoryRootKids = 'detskoe';
export const categoryRootLifestyle = 'lifestyle';

export const categoryRootWomenId = 2;
export const categoryRootMenId = 105;
export const categoryRootKidsId = 188;
export const categoryRootLifestyleId = 366;

export const categoryRootById = {
  [categoryRootWomenId]: categoryRootWomen,
  [categoryRootMenId]: categoryRootMen,
  [categoryRootKidsId]: categoryRootKids,
  [categoryRootLifestyleId]: categoryRootLifestyle,
};

export const categoryRootByName = {
  [categoryRootWomen]: { id: categoryRootWomenId, label: 'Женское' },
  [categoryRootMen]: { id: categoryRootMenId, label: 'Мужское' },
  [categoryRootKids]: { id: categoryRootKidsId, label: 'Детское' },
  [categoryRootLifestyle]: { id: categoryRootLifestyleId, label: 'Lifestyle' },
};

export default categoryRootById;
